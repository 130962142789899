import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { urlSafe } from 'src/app/commons/utils/http.util';
import { API_URL } from 'src/app/commons/constants/config.constant';
import { User, UserShort } from 'src/app/commons/models/users.model';
import { Pagination } from '../../models/utils.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  USER_API = urlSafe([API_URL, 'users'])
  FORGOT_PASSWORD_API = urlSafe([this.USER_API, 'forgot-password'])
  // auth user container
  user!: User;

  userList: UserShort[] = []

  constructor(
    private $http: HttpClient
  ) {}

  retrieve(): Observable<User> {
    return this.$http.get<User>(urlSafe([this.USER_API, 'auth'])).pipe(
      tap((response: User) => {
        this.user = response;
      })
    )
  }

  list(params = {}){
    return this.$http.get<Pagination<User>>(urlSafe([this.USER_API], params))
  }

  forgotPassword(data: any) {
    return this.$http.post(urlSafe([this.FORGOT_PASSWORD_API, 'request']), data)
  }

  resetPassword(data: any) {
    return this.$http.post(urlSafe([this.FORGOT_PASSWORD_API]), data)
  }

  validateResetCode(code: any) {
    return this.$http.get(urlSafe([this.FORGOT_PASSWORD_API, 'validate', code]))
  }

}