import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';


import { TokenService } from "./commons/services/interceptors/token.service";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// what is the use of this? if this is related to ng-neat.
// remove it when ng-neat is totally wiped out in the system
import { ErrorTailorProvider } from './err-tailor.config';
import { provideQueryClientOptions } from '@ngneat/query';

import { QuillConfigModule } from 'ngx-quill/config';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientJsonpModule,
    HttpClientModule,
    NgSelectModule,
    QuillConfigModule.forRoot({
      modules: {
        syntax: false,
      }
    }),
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenService,
    multi: true,
  },
    ErrorTailorProvider,
    provideQueryClientOptions({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 2
        },
      }
    }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
