import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { Sprint } from 'src/app/commons/models/boards.model';
import { urlSafe } from 'src/app/commons/utils/http.util';
import { API_URL } from 'src/app/commons/constants/config.constant';

@Injectable({
  providedIn: 'root'
})
export class SprintService {

  API_BOARD = urlSafe([API_URL, 'boards']);
  sprints!: Sprint[];

  constructor(
    private $http: HttpClient,
  ) { }

  list(boardId: number, params={}): Observable<Sprint[]> {
    return this.$http.get<Sprint[]>(
      urlSafe([this.API_BOARD, boardId, 'sprints'], params)
    ).pipe(
      tap((response: Sprint[]) => {
        this.sprints = response;
      })
    );
  }

  create(boardId: number, data: Sprint): Observable<Sprint> {
    return this.$http.post<Sprint>(
      urlSafe([this.API_BOARD, boardId, 'sprints']),
      data
    )
  }

  retrieve(boardId: number, sprintId: number): Observable<Sprint> {
    return this.$http.get<Sprint>(
      urlSafe([this.API_BOARD, boardId, 'sprints', sprintId]),
    )
  }

  update(boardId: number, sprintId: number, data: any): Observable<Sprint> {
    return this.$http.put<Sprint>(
      urlSafe([this.API_BOARD, boardId, 'sprints', sprintId]),
      data
    )
  }

  startSprint(boardId: number, sprintId: number): Observable<Sprint> {
    return this.$http.post<Sprint>(
      urlSafe([this.API_BOARD, boardId, 'sprints', sprintId, 'start']),
      {}
    )
  }

  delete(boardId: number, sprintId: number): Observable<any> {
    return this.$http.delete(
      urlSafe([this.API_BOARD, boardId, 'sprints', sprintId]),
    )
  }
}
