

import dayjs from "dayjs";

// https://day.js.org/docs/en/parse/string-format
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import calendar from "dayjs/plugin/calendar";
import duration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import { enLocale, enShortLocale } from "./dayjs.locale";

// Add plugin if necessary
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.extend(calendar)
dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(localeData)
dayjs.extend(isoWeek)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

// add short time format in time ago
dayjs.locale('en-short',enShortLocale,true)
dayjs.updateLocale('en',enLocale)

export default dayjs;
