import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { ProjectService } from 'src/app/commons/services/projects/project.service';
import { StageService } from 'src/app/commons/services/workspace/stage.service';
import { EpicService, IssueTypeService, PriorityService, TagService } from 'src/app/commons/services/workspace/board.service';
import { SprintService } from './sprint.service';

import { API_URL } from 'src/app/commons/constants/config.constant';
import { PaginatedResult } from 'src/app/commons/models/common.model';
import { Criteria, Ticket } from 'src/app/commons/models/boards.model';

import { urlSafe } from 'src/app/commons/utils/http.util';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  BOARD_API = urlSafe([API_URL, 'boards'])

  constructor(
    private $http: HttpClient,
    public $project: ProjectService,
    public $stage: StageService,
    public $priority: PriorityService,
    public $issueType: IssueTypeService,
    public $tag: TagService,
    public $sprint: SprintService,
    public $epic: EpicService
  ) { }

  listTickets: Ticket[] = []
  list(boardId: number, params?: any): Observable<PaginatedResult> {
    return this.$http.get<PaginatedResult>(
      urlSafe([this.BOARD_API, boardId, 'tickets'], params)
    ).pipe(tap((resp)=>{
      resp.results.forEach((re)=>{
        const index = this.listTickets.findIndex(ticket => ticket.id === re.id)
        if (index < 0) {
          this.listTickets.push(re)
        } else {
          this.listTickets[index] = re
        }
      })
    }))
  }

  create(boardId: number, data: Ticket): Observable<Ticket> {
    return this.$http.post<Ticket>(
      urlSafe([this.BOARD_API, boardId, 'tickets']),
      data
    )
  }

  retrievesTicket: Ticket[] = []
  retrieve(boardId: number, ticketId: number): Observable<Ticket> {
    return this.$http.get<Ticket>(
      urlSafe([this.BOARD_API, boardId, 'tickets', ticketId])
    ).pipe(tap((resp)=>{
      const ticket = this.retrievesTicket.find(ticket => ticket.id === ticketId)
      if (!ticket) {
        this.retrievesTicket.push(resp)
      } else {
        Object.assign(ticket, resp)
      }
    }))
  }

  updateAllTicketReferences(ticket: Ticket){
    // Update all reference tickets
    const listTicket = this.listTickets.find(t => t.id === ticket.id) 
    if (listTicket) {
      Object.assign(listTicket, ticket)
    }
    const retrieveTicket = this.retrievesTicket.find(t => t.id === ticket.id) 
    if (retrieveTicket) {
      Object.assign(retrieveTicket, ticket)
    }
  }

  update(boardId: number, ticketId: number, data: any): Observable<Ticket> {
    return this.$http.put<Ticket>(
      urlSafe([this.BOARD_API, boardId, 'tickets', ticketId]),
      data
    ).pipe(tap((resp)=>{
      this.updateAllTicketReferences(resp)
    }))
  }

  delete(boardId: number, ticketId: number, data: any): Observable<Ticket> {
    return this.$http.put<Ticket>(
      urlSafe([this.BOARD_API, boardId, 'tickets', ticketId]),
      data
    )
  }

  deleteTicketBacklog(boardId: number, ticketId: number): Observable<any> {
    return this.$http.delete<any>(
      urlSafe([this.BOARD_API, boardId, 'tickets', ticketId])
    )
  }

  bulkUpdate(boardId: number, data: any[]): Observable<Ticket[]> {
    return this.$http.put<Ticket[]>(
      urlSafe([this.BOARD_API, boardId, 'tickets']),
      data
    )
  }

  ticketMedia(data: any): Observable<any> {
    return this.$http.post<any>(
      urlSafe([this.BOARD_API, 'media']),
      data
    )
  }

  updateCriteria(ticketId: number, criteriaId: number, data: any): Observable<Criteria> {
    return this.$http.put<Criteria>(
      urlSafe([this.BOARD_API, 'tickets', ticketId, 'criteria', criteriaId]),
      data
    )
  }

  deleteCriteria(ticketId: number, criteriaId: number): Observable<any> {
    return this.$http.delete<any>(
      urlSafe([this.BOARD_API, 'tickets', ticketId, 'criteria', criteriaId])
    )
  }

  addCriteria(ticketID: number, data: any): Observable<any> {
    return this.$http.post<any>(
      urlSafe([this.BOARD_API, 'tickets', ticketID, 'criteria']),
      data
    )
  }

  uploadAttachment(ticketId: number, data: any): Observable<any> {
    return this.$http.post<any>(
      urlSafe([this.BOARD_API, 'tickets', ticketId, 'attachments']),
      data
    )
  }

  tracker(ticketId: number, data: any): Observable<any> {
    return this.$http.post<any>(
      urlSafe([this.BOARD_API, 'tickets', ticketId, 'trackers']),
      data
    )
  }

  // getTracker(ticketId: number): Observable<any> {
  //   return this.$http.get<any>(
  //     urlSafe([this.BOARD_API, 'tickets', ticketId, 'trackers'])
  //   )
  // }

}
