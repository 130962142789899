import { Pipe, PipeTransform } from '@angular/core';
import { isString } from "../utils/helpers.util";

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(!value) return;
    if (isString(value)) return value;
    return URL.createObjectURL(value as File);
  }

}

@Pipe({
  name: 'imageName'
})
export class ImageNamePipe implements PipeTransform {

  constructor() { }

  transform(value: any, ...args: any[]): string {
    const fileName = value.split('/').pop()
    return fileName;
  }
}

@Pipe({
  name: 'assignDefaultImage'
})
export class AssignDefaultImagePipe implements PipeTransform {

  constructor() { }

  transform(value: any, ...args: any[]): string {
    if (!value) return 'assets/images/avatars/new-default.svg';
    return value;
  }
}

@Pipe({
  name: 'projectLogo'
})
export class ProjectLogoPipe implements PipeTransform {

  constructor() { }

  transform(value: any, ...args: any[]): string {
    if (!value) return 'assets/images/projects/default-logo.svg';
    return value;
  }
}
