import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Board, Member, Ticket } from 'src/app/commons/models/boards.model';

import { User } from 'src/app/commons/models/users.model';
import { AuthService } from 'src/app/commons/services/users/auth.service';
import { TimeTrackerService } from 'src/app/commons/services/workspace/time-tracker.service';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.scss'
})
export class MainHeaderComponent implements OnChanges {

  @Input() ticket!: Ticket
  @Input() ticketTime: string = '00:00:00'

  constructor(
    private $auth: AuthService,
    public $timeTracker: TimeTrackerService,
    private cdr: ChangeDetectorRef,
    private $route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.members.forEach((member: Member) => {
    //   console.log('Member', member)
    //   if (member.user.id === this.user.id) {
    //     this.$timeTracker.headerTicket = member.in_progress_ticket
    //   }
    // })
  }
  
  ngOnChanges(): void {
    // console.log(this.board)    
    // this.members.forEach((member: Member) => {
    //   if (member.user.id === this.user.id) {
    //     this.$timeTracker.headerTicket = member.in_progress_ticket
    //   }
    // })
    // this.cdr.detectChanges();
  }

  startTimer(ticket: Ticket) {
    this.$timeTracker.startTimer(ticket);
  }

  stopTimer() {
    this.$timeTracker.stopTimer();
  }

  get board(): Board {
    return this.$route.snapshot.data['board'];
  }

  get members(): any[] {
    return this.board.members;
  }

  get user(): User {
    return this.$auth.$user.user;
  }

  logout(): void {
    this.$auth.logout();
  }

}
