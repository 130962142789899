import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from './auth.service';

export const userResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  let $auth = inject(AuthService);

  let isAuthenticated;
  $auth.isAuthenticated().subscribe((value: boolean) => isAuthenticated = value)

  if (isAuthenticated) {
    return $auth.$user.retrieve();
  } else {
    return null;
  }
}