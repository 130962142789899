import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'src/app/commons/utils/helper.util';

@Pipe({
  name: 'objIsEmpty'
})
export class ObjectIsEmptyPipe implements PipeTransform {

  transform(value: Object): boolean {
    return isEmpty(value);
  }

}

@Pipe({
  name: 'sortByPosition'
})
export class SortByPositionPipe implements PipeTransform {
  transform(value: any[], ...args: unknown[]): any[] {
    return value.sort((a, b) => a.position - b.position);
  }
}

@Pipe({ name: 'key',  pure: false })
export class KeyPipe implements PipeTransform {
    transform(value: any, args: any[] = []): any {
        return Object.keys(value)
    }
}

@Pipe({ name: 'timeFromSeconds' })
export class TimeFromSecondsPipe implements PipeTransform {
  transform(value: number): string {
    const seconds = Math.floor(value);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const formattedSeconds = seconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${formattedSeconds.toString().padStart(2, '0')}`;
  }
}