import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { Stage } from 'src/app/commons/models/boards.model';
import { urlSafe } from 'src/app/commons/utils/http.util';
import { API_URL } from 'src/app/commons/constants/config.constant';

@Injectable({
  providedIn: 'root'
})
export class StageService {

  DONE = 'done'
  WIP = 'wip'
  ICEBOX = 'icebox'

  CODES = {
    todo: 'to_do'
  }

  API_BOARD = urlSafe([API_URL, 'boards']);
  stages: Stage[] = [];

  constructor(
    private $http: HttpClient,
  ) { }

  list(boardId: number): Observable<Stage[]> {
    return this.$http.get<Stage[]>(
      urlSafe([this.API_BOARD, boardId, 'stages'])
    ).pipe(
      tap((response: Stage[]) => {
        this.stages = response;
      })
    );
  }

  create(boardId: number, data: Stage): Observable<Stage> {
    return this.$http.post<Stage>(
      urlSafe([this.API_BOARD, boardId, 'stages']),
      data
    )
  }

  retrieve(boardId: number, stageId: number): Observable<Stage> {
    return this.$http.get<Stage>(
      urlSafe([this.API_BOARD, boardId, 'stages', stageId]),
    )
  }

  update(boardId: number, stageId: number, data: Stage): Observable<Stage> {
    return this.$http.put<Stage>(
      urlSafe([this.API_BOARD, boardId, 'stages', stageId]),
      data
    )
  }

  // store hidden stages in the local storage
  hideStage(stage: Stage): void {
    let hidden = JSON.parse(localStorage.getItem('hidden_stages') || '[]');
    const index = hidden.indexOf(stage.id);
    if(index > -1) {
      hidden.splice(index, 1);
    } else {
      hidden.push(stage.id);
    }
    localStorage.setItem('hidden_stages', JSON.stringify(hidden));
  }

  getHiddenStages(): any[] {
    return JSON.parse(localStorage.getItem('hidden_stages') || '[]');
  }

  getVisibleStages(): any[] {
    return this.stages.filter(stage => !this.getHiddenStages().includes(stage.id));
  }

  //-- SHORTCUT METHODS
  // helps process the module easier and faster

  getByCode(code: string | undefined): Stage {
    return this.stages.find(t => t.code === code) || {} as Stage;
  }

}
