/**
 * Customize dayjs locale text
 */

export const enShortLocale: any =  {
    relativeTime: {
        future: "%s",
        past: "%s",
        s: '1s ago',
        m: "a min ago",
        mm: "%dmin ago",
        h: "1h ago",
        hh: "%dh ago",
        d: "1dd ago",
        dd: "%dd ago",
        M: "1 m ago",
        MM: "%d m ago",
        y: "1 y ago",
        yy: "%d y ago"
    }
}

export const enLocale = {
    calendar: {
        sameDay: '[Today]', // The same day ( Today at 2:30 AM )
        nextDay: 'Tomorrow', // The next day ( Tomorrow at 2:30 AM )
        nextWeek: 'dddd', // The next week ( Sunday at 2:30 AM )
        lastDay: '[Yesterday]', // The day before ( Yesterday at 2:30 AM )
        lastWeek: '[Last] dddd', // Last week ( Last Monday at 2:30 AM )
        sameElse: '[Past]' // Everything else ( 17/10/2011 )
    }
}