import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, tap } from 'rxjs';
import { UseMutation, UseQuery } from '@ngneat/query';

import { Pagination } from 'src/app/commons/models/utils.model';
import { Board, IssueType, Priority, Ticket, Tag, Epic } from 'src/app/commons/models/boards.model';
import { API_BOARDS } from 'src/app/commons/constants/api.constant';

import { urlSafe } from 'src/app/commons/utils/http.util';
import { API_URL } from 'src/app/commons/constants/config.constant';

@Injectable({
  providedIn: 'root'
})
export class BoardService {
  useMutate = inject(UseMutation)
  
  constructor(
    private $http: HttpClient,
    private useQuery: UseQuery, 
    private router: Router,
  ) { }

  // should be renamed to `retrieve` and delete all the trash code below
  get(id: number): Observable<Board> {
    return this.$http.get<Board>(urlSafe([API_URL, 'boards', id]))
  }

  list() {
    return this.useQuery(['boards'], () => {
      return this.$http.get<Pagination<Board>>(urlSafe([API_BOARDS], {
        page_size: 10,
      }));
    });
  }

  retrieve(id: number) {
    return this.useQuery(['boards', id], () => {
      return this.$http.get<Board>(urlSafe([API_BOARDS, id]));
    });
  }

  retrieveTickets(boardID: number, params = {}) {
    return this.useQuery(['boards', boardID, 'tickets'], () => {
      return this.$http.get<Pagination<Ticket>>(urlSafe([API_BOARDS, boardID, 'tickets']));
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class PriorityService {

  API_BOARD = urlSafe([API_URL, 'boards']);
  priorities!: Priority[];

  constructor(
    private $http: HttpClient
  ) {}

  list(): Observable<Priority[]> {
    return this.$http.get<Priority[]>(
      urlSafe([this.API_BOARD, 'priorities'])
    ).pipe(
      tap((response: Priority[]) => {
        this.priorities = response;
      })
    );
  }

}

@Injectable({
  providedIn: 'root'
})
export class IssueTypeService {

  API_BOARD = urlSafe([API_URL, 'boards']);
  issueTypes!: IssueType[];

  TASK = 2
  BUG = 3

  constructor(
    private $http: HttpClient
  ) {}

  list(): Observable<IssueType[]> {
    return this.$http.get<IssueType[]>(
      urlSafe([this.API_BOARD, 'issue-types'])
    ).pipe(
      tap((response: IssueType[]) => {
        this.issueTypes = response;
      })
    );
  }

}


@Injectable({
  providedIn: 'root'
})
export class TagService {

  API_BOARD = urlSafe([API_URL, 'boards']);
  tags!: Tag[];

  constructor(
    private $http: HttpClient
  ) {}

  list(boardId: number): Observable<Tag[]> {
    return this.$http.get<Tag[]>(
      urlSafe([this.API_BOARD, boardId, 'tags'])
    ).pipe(
      tap((response: IssueType[]) => {
        this.tags = response;
      })
    );
  }

}

@Injectable({
  providedIn: 'root'
})
export class EpicService {

  API_BOARD = urlSafe([API_URL, 'boards']);
  epics: Epic[] = [];

  constructor(
    private $http: HttpClient
  ) {}

  list(id: any, params = {}): Observable<Epic[]> {
    return this.$http.get<Epic[]>(
      urlSafe([this.API_BOARD, id, 'epics'], params)
    ).pipe(
      tap((response: Epic[]) => {
        this.epics = response;
      })
    );
  }

  delete(boardId: any, id: any): Observable<null> {
    return this.$http.delete<null>(
      urlSafe([this.API_BOARD, boardId, 'epics', id])
    ).pipe();
  }

  create(boardId: any, data = {}): Observable<Epic> {
    return this.$http.post<Epic>(
      urlSafe([this.API_BOARD, boardId, 'epics']), data
    ).pipe();
  }

  update(boardId: any, epicId: any, data = {}): Observable<Epic> {
    return this.$http.put<Epic>(
      urlSafe([this.API_BOARD, boardId, 'epics', epicId]), data
    ).pipe();
  }
}