import { Injectable, OnInit } from '@angular/core';
import { TicketService } from './ticket.service';
import { Board, Ticket } from '../../models/boards.model';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackerService {

  ticket!: Ticket | null

  elapsedTime: number = 0
  isTimerStarted!: boolean;

  timerInterval: any;
  startTime: Date | null = null;

  isTicketTracking: boolean = false;

  constructor(
    private $ticket: TicketService,
    private $route: ActivatedRoute
  ) {}

  updateElapsedTime() {
    if (this.ticket) {
      if (this.startTime) {
        this.elapsedTime += 1;
      }
    }

  // console.log('update elapsed time: ', this.elapsedTime)
  }

  

  startTimer(ticket: Ticket) {
    if (!this.isTimerStarted) {

      this.$ticket.tracker(ticket.id, {}).subscribe({
        next: () => {
          this.startTime = new Date();
          this.isTimerStarted = true;
          this.ticket = ticket;
          this.elapsedTime = this.elapsedTime;
          this.timerInterval = setInterval(() => this.updateElapsedTime(), 1000);        }
      });
    }
  }

  get board(): Board {
    return this.$route.snapshot.data['board'];
  }

  stopTimer() {
    if (this.isTimerStarted) {
      if (this.ticket) {
        this.$ticket.tracker(this.ticket.id, {}).subscribe({
          next: (res) => {
            this.ticket = null;
            clearInterval(this.timerInterval);
            const elapsedTime = this.startTime ? Math.floor((new Date().getTime() - this.startTime.getTime()) / 1000) : 0;
            this.startTime = null;
            this.isTimerStarted = false;
            this.elapsedTime = res.ticket.time_spent;
            this.startTime = null;
            console.log('stop timer: ', this.elapsedTime)
          }
        })
      }
    }
  }

  initializeTimer() {
    // Fetch the initial state of the timer from the server
    if (this.board && this.ticket) {
      this.$ticket.retrieve(this.board.id, this.ticket.id).subscribe({
        next: (ticket) => {
          this.elapsedTime = ticket.time_spent ?? 0;
          if (ticket.latest_timestamp) {
            const startDate = new Date(ticket.latest_timestamp.timestamp);
            
            if (!ticket.latest_timestamp.has_ended) {
              this.startTime = startDate;
              this.isTimerStarted = true;
              this.elapsedTime = ticket.time_spent + ((new Date().getTime() - new Date(ticket.latest_timestamp.timestamp).getTime()) / 1000); // Set the initial elapsed time
              this.timerInterval = setInterval(() => this.updateElapsedTime(), 1000);
            } else {
              this.elapsedTime = ticket.time_spent;
            }
          }
        }
      });
    }
  }
}
