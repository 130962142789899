import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { Project } from 'src/app/commons/models/boards.model';
import { urlSafe } from 'src/app/commons/utils/http.util';
import { API_URL } from 'src/app/commons/constants/config.constant';
import { PaginatedResult } from '../../models/common.model';
import { Pagination } from "../../models/utils.model";


@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private PROJECT_API = urlSafe([API_URL, 'projects']);
  // projects container
  projects!: Pagination<Project>;

  constructor(
    private $http: HttpClient,
  ) { }

  list(): Observable<Pagination<Project>> {
    return this.$http.get<Pagination<Project>>(urlSafe([this.PROJECT_API])).pipe(
      tap((response) => {
        this.projects = response;
      })
    );
  }

}
