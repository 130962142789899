import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginRequired } from './commons/services/interceptors/permission.service';
import { userResolver } from './commons/services/users/user.resolver';
import { AuthUserLayoutComponent } from './shared/components/_layouts/auth-user-layout/auth-user-layout.component';


const AUTH_ROUTES: Routes = [
  {
    path: '',
    component: AuthUserLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'workspaces',
        loadChildren: () => import('./pages/workspaces/workspace.module').then(m => m.WorkspaceModule)
      },
    ]
  }
]


const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    resolve: {
      user: userResolver,
    },
    canActivate: [LoginRequired],
    children: AUTH_ROUTES
  },
  {
    path: '',
    loadChildren: () => import('./pages/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'errors',
    loadChildren: ()=> import('src/app/pages/errors/errors.module').then(v=> v.ErrorsModule)
  },
  { 
    path: '**',
    pathMatch: 'full',
    redirectTo: 'errors/not-found'
  }, 
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, {
    paramsInheritanceStrategy: 'always',
    preloadingStrategy: PreloadAllModules,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
