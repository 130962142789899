import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { API_URL, AUTH_KEY } from 'src/app/commons/constants/config.constant';
import { urlSafe } from 'src/app/commons/utils/http.util';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(this.getToken() !== null);

  constructor(
    private $http: HttpClient,
    private $router: Router,
    public $user: UserService,
  ) {}

  login(email: string, password: string): Observable<any> {
    console.log(email, password);
    return this.$http.post<any>(urlSafe([API_URL, 'login']), { email, password }).pipe(
      tap((response: { token: any; }) => {
        this.saveToken(response.token);
        this.isAuthenticatedSubject.next(true);
      })
    );
  }

  logout(): void {
    localStorage.removeItem(AUTH_KEY);
    this.isAuthenticatedSubject.next(false);

    this.$router.navigate(['/login']);
  }

  isAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  private saveToken(token: string): void {
    localStorage.setItem(AUTH_KEY, token);
  }

  getToken(): string | null {
    return localStorage.getItem(AUTH_KEY);
  }

}