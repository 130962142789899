<header class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
        <button class="btn reset logo d-flex align-items-center">
            <img class="tactiv-logo" src="assets/logo/tactiv.svg" alt="">
            <img class="tactiv-text-logo" src="assets/logo/tactiv-text-logo.svg" alt="">
        </button>

        <nav>
            <ul>
                <li>
                    <a 
                        routerLinkActive="active"
                        routerLink="/dashboard"
                        class="active-state"
                    >
                        Home
                    </a>
                </li>
                <li>
                    <a 
                        routerLinkActive="active"
                        routerLink="/workspaces"
                        class="active-state"
                    >
                        Workspaces
                    </a>
                </li>
                <li>
                    <a 
                        routerLinkActive="active"
                        routerLink="/projects"
                        class="active-state"
                    >
                        Projects
                    </a>
                </li>
            </ul>
        </nav>
    </div>

    <div class="user-nav d-flex align-items-center gap-3">
        @if ($timeTracker.ticket) {
            <div class="bg-neutral-25 border rounded-circle border-neutral-50 w-100 d-flex align-items-center gap-12 p-2 ps-20">
                <div class="ticket-num d-flex align-items-center">
                    <p>{{ $timeTracker.ticket.ticket_num }}</p>
                    <i class="ph ph-arrow-square-in"></i>
                </div>
    
                <div class="ticket-time d-flex align-items-center gap-2">
                    <p>{{ $timeTracker.elapsedTime | timeFromSeconds }}</p>

                    @if (!$timeTracker.isTimerStarted) {
                        <button class="btn btn-primary rounded-circle px-2 align-items-center justify-content-center h-32" (click)="$timeTracker.startTimer(ticket)">
                            <i class="ph-fill ph-play text-white"></i>
                        </button>
                    } @else if ($timeTracker.isTimerStarted) {
                        <button class="btn btn-danger rounded-circle px-2 align-items-center justify-content-center h-32" (click)="$timeTracker.stopTimer()">
                            <i class="ph-fill ph-stop text-white"></i>
                        </button>
                    }
    
    
                </div>
            </div>
        }

        <div class="default-actions d-flex align-items-center gap-1">
            <button class="btn icon-only">
                <i class="ph ph-gear"></i>
            </button>
            <button class="btn icon-only">
                <i class="ph ph-bell"></i>
            </button>
        </div>

        <button class="btn reset user-icon" ngbDropdown>
            <img class="rounded-circle avatar" [src]="user.photo | assignDefaultImage" alt="" ngbDropdownToggle>

            <div ngbDropdownMenu class="user-menu w-100 p-0">
                <div class="d-flex align-items-center gap-2 p-12">
                    <button class="btn reset user-icon">
                        <img class="rounded-circle" [src]="user.photo | assignDefaultImage" alt="">
                    </button>

                    <div class="d-flex flex-column gap-1">
                        <h5>{{ user.display_name }}</h5>
                        <p>{{ user.email }}</p>
                    </div>
                </div>
                <a class="d-flex align-items-center p-12" ngbDropdownItem (click)="$event.preventDefault(); logout()">
                    <i class="ph ph-sign-out me-2 fs-20"></i>
                    Logout
                </a>
            </div>
        </button>
    </div>
</header>