import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import dayjs from "dayjs";
import Swal from "sweetalert2"

/* Check if object is empty.
 */
export const objIsEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
}

export const objectToFormData = (data: any)=> {
  const toFormData = ((f) => f(f))(
    (h: any) => (f: any) => f((x: any) => h(h)(f)(x))
  )((f: any) => (fd = new FormData()) => (pk: any) => (d: any) => {
    if (d instanceof Object) {
      Object.keys(d).forEach((k) => {
        const v = d[k];
        if (pk) k = `${pk}[${k}]`;
        if (v instanceof Object && !(v instanceof Date) && !(v instanceof File)) {
          return f(fd)(k)(v);
        } else {
          fd.append(k, v ?? "");
        }
      });
    }
    return fd;
  })()();

  return toFormData(data)
}

// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export function humanFileSize(size: number) {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return +(size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export function isString(str: any){
  return typeof str === 'string' || str instanceof String;
}

export interface WaiterState {
  unexpected: boolean,
  notFound: boolean,
  loading: boolean,
  error: boolean,
  touched: boolean
  handle: (pm: Promise<any>) => Promise<[any, any]>
  clearState: ()=> void
  onSuccess?: (data: any)=> any
  onError?: (data: any)=> any
}

export const usePromise = async <T>(promise: Promise<T>): Promise<[T, any]> => {
  try {
    const result = await promise;
    return [result, null]
  }
  catch (error) {
    return [null as any, error]
  }
}

export const HttpHandler = () => {
  const defaultState  = {
    unexpected: false,
    notFound: false,
    loading: false,
    error: false,
    touched: false
  }
  const state: WaiterState = {
    ...defaultState,
    handle: async (pm)=>{
      !state.touched && (state.touched = true)

      state.clearState()

      state.loading = true;
      const [res,err] = await usePromise(pm);
      if (!err) state.onSuccess && state.onSuccess(res)

      if (err && err.status) {
        if (err.status >= 500) {
          state.unexpected = true
        } else if (err.status === 404) {
          state.notFound = true
        }
        state.error = true;
        state.onError && state.onError(err)
      }



      state.loading = false;
      return [res,err]
    },
    clearState: function (){
      const temp = this.touched
      Object.assign(this,defaultState)
      this.touched = temp
    }
  };

  return state;
}

export function format(date: NgbDateStruct): string | null {
  return date ? dayjs(`${date.year}-${date.month}-${date.day}`).format('YYYY-MM-DD') : null
}


export function dateToNgbDate(date: string): NgbDateStruct | null {
  return dayjs(date).isValid() ? {
    year: dayjs(date).year(),
    month: dayjs(date).month() + 1,
    day: dayjs(date).date()
  } : null;
}
