// `endsWithAny` method check if the string

import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import dayjs from "dayjs";

// ends with any in the list.
export const endsWithAny = (str: string, extensions: string[]): boolean => {
  return extensions.some(ext => str.endsWith(ext));
}

// `isEmpty` method checks the object if all values
// are null (it means it is empty).
export const isEmpty = (obj: { [key: string]: any }): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null) {
      return false;
    }
  }
  return true;
}

// `toUrlEncodedString` method converts object string
// to url encoded string
export const toUrlEncodedString = (params: Record<string, string | number>): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined) {
      value = ""; // convert undefined to empty string
    }
    searchParams.append(key, value.toString());
  });
  return '?' + searchParams.toString();
}


export function checkFieldExists(item: any, field: string, value: any) {
  const fieldPath = field.split('.');
  let fieldValue = item;
  for (let key of fieldPath) {
    if (fieldValue[key] === undefined) return false;
    fieldValue = fieldValue[key];
  }
  return fieldValue === value;
}


export function filterByDates(date: any, selectedDates: any[]) {
  let ticketDueDate = dayjs(date);
  const today = dayjs();
  return selectedDates.some((date) => {
    return ticketDueDate.isBetween(today, dayjs(date), 'day', '[]')
  });
}


export function cloneObj(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export const toDateStr = (date: NgbDate,format = "YYYY-MM-DD")=>{
  return dayjs(`${date.year}-${date.month}-${date.day}`,"YYYY-MM-DD").format(format);
} 