import { urlSafe } from 'src/app/commons/utils/http.util';
import { API_URL } from './config.constant';


export const API_LOGIN = urlSafe([API_URL, 'login']);

export const API_USERS = urlSafe([API_URL, 'users']);

export const API_AUTH = urlSafe([API_USERS, 'auth']);

export const API_BOARDS = urlSafe([API_URL, 'boards']);

export const API_PROJECTS = urlSafe([API_URL, 'projects']);