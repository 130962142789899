import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/commons/services/users/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private $auth: AuthService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    let authenticated = false;
    this.$auth.isAuthenticated().subscribe((value: boolean) => authenticated = value);

    return authenticated;
  }
}

export const LoginRequired: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
  let isAuthenticated = inject(PermissionService).canActivate(route, state);

  if (!isAuthenticated) {
    inject(Router).navigate(['/login']);
  }
  return true;
}

export const AlreadyLoggedIn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
  let isAuthenticated = inject(PermissionService).canActivate(route, state);

  if (isAuthenticated) {
    return inject(Router).navigate(['/dashboard']);
  }
  return true;
}