import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: { class: 'component-container' }
})
export class AppComponent {
  
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
