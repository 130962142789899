import { provideErrorTailorConfig } from "@ngneat/error-tailor";

export const ErrorTailorProvider = provideErrorTailorConfig({
  controlErrorsOn: {
    blur: true,
  },
  controlErrorsClass: 'fv-plugins-message-container',
  errors: {
    useValue: {
      required: 'This field is required',
      email: 'This email is invalid',
      minlength: ({ requiredLength, actualLength }) =>
        `Expect ${requiredLength} but got ${actualLength}`,
      maxlength: ({ requiredLength, actualLength }) =>
        `Maximum ${requiredLength} but got ${actualLength}`,
      error: error => error,
    },
  }
})